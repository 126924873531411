#resume {
    background-color: #ebeff0;
    margin-top: 4rem;
    width: 100vw;
}

/* patterned background behind heading */
.resume-background {
    background-color: #ebeff0;
    background-size: 10px 10px;
    background-attachment: fixed;
    position: relative;
    width: 70%;
    height: 180px;
    margin-left: 10rem;
    margin-top: 4rem;
    background-image: repeating-linear-gradient(45deg, #aedbde 0, #aedbde 1px, #ebeff0 0, #ebeff0 50%);
}

.resume-heading {
    text-align: left;
    position: relative;
    z-index: 1;
    font-size: 5rem;
    margin-top: -11rem;
    margin-bottom: 5rem;
    margin-left: 3rem;
    color: #222d3e;
    font-family: 'Roboto', sans-serif;
}

.resume-subtitle {
    text-align: left;
    font-size: 1.3rem;
    margin-bottom: .5rem;
    margin-left: 3rem;
    color: #222d3e;
    margin-top: -7rem;
    font-family: 'Open Sans', sans-serif;
    width: 60%;
}

.resume-subtitle h2 span {
    color:#78c4c8;
}

/* line underneath heading */
.resume-line {
    width: 100px;
    margin-left: 3rem;
    border-radius: 5px;
    height: 4px;
    background: #78c4c8;
    margin-top: -4rem;
    margin-bottom: 10rem;
}

.resume-info {
    font-family: 'Open Sans', sans-serif;
    color: #222d3e;
    font-size: 1.5rem;
    margin-left: 10rem;
    margin-top: 8rem;
    position: relative;
    display: inline-flex;
}


.desk-svg {
    top: 63%;
    left: 58%;
    margin: -50px 0 0 -50px;
    position: absolute;
}

.flower-svg {
    position: absolute;
    display: inline-flex;
    margin-top: 5rem
}


.resume-scroll {
    font-family: 'League Spartan', sans-serif;
    letter-spacing: 3px;
    margin-top: 10rem;
    margin-left: 15rem;
    font-size: 11px;
    text-transform: uppercase;  
}

.resume-scroll-line {
    height: 150px;
    border-left: 1px solid #222d3e;
    place-self: center;
    margin-left: 1.5rem;
    margin-top: .5rem;
}


.timeline-container {
    width: 90%;
    height: auto;
    margin: auto;
    border-radius: 5px;
    margin-top: 10rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 8rem;
}

/* The vertical timeline */
.timeline {
    position: relative;
    margin: 0 auto;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    z-index: 0;
}
  
/* Container around content */
  .container-right, .container-left  {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    overflow-x: visible !important;
    box-sizing: border-box;
    z-index: 1;
}
  
/* Circles */ 
.container-right::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: #78c4c8;
    border: 4px solid #222d3e;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.container-left::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: #78c4c8;
    border: 4px solid #222d3e;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Fix the circle for containers on the right side */
  .container-right::after {
    left: -16px;
}
  
/* Place the container to the left */
  .containter-left {
    left: 0;
}
  
/* Place the container to the right */
  .container-right {
    left: 50%;
}
  
/* Add right-pointing arrows to the left container */
.container-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #ffff;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}
  
/* Add left-pointing arrows to the right container */
.container-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #ffff;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ffff transparent transparent;
}
  
/* content information */
.content {
    padding: 20px 30px;
    background-color: #ffff;
    font-family: 'Open Sans';
    letter-spacing: .5px;
    position: relative;
    border-radius: 6px; 
}

.content h2 {
    color: #78c4c8;
    font-size: 1.8rem;
    margin-top: -1rem;  
}

.content h3 {
    font-size: .75rem;
    padding: 0;
    margin-top: -20px;
}

.content p {
    font-size: 1.3rem;
}

.content p span {
    color: #78c4c8;
}


/*--MEDIA QUERIES--*/

/*--Small/Medium Screens--*/
@media only screen and (min-width: 576px) and (max-width: 850px) {

    .resume-info {
        margin-left: 3rem;
        width: 60%;
    }

    .desk-svg {
        top: 48%;
    }

    .flower-svg {
       margin-left: -4rem;
    }

    .timeline-container {
        padding-bottom: 9rem;
    }
}

/*--Extra Small/Small Screens--*/
@media only screen and (min-width: 300px) and (max-width: 575px) {
    
    .resume-background {
        margin-left: 6rem;
    }

    .resume-heading {
        margin-top: -10.5rem;
        font-size: 3.9rem;
        margin-left: 3rem;
    }

    .resume-line {
        margin-bottom: 3rem;
    }

    .resume-subtitle {
        width: 75%;
        font-size: 1.2rem;
        margin-top: 4rem;  
    }

    .resume-info {
        margin-left: 3rem;
        margin-top: 6rem;
        font-size: 1rem;
        width: 45%;
    }

    .desk-svg {
        margin-top: 3rem;
        position: relative;
        left: 60%;
    }

    .desk-svg img {
        width: 170px;
    }

    .flower-svg {
        margin-top: 1rem;
        position: relative;
        margin-left: 10rem;
        padding-left: 1.5rem;
    }

    .resume-scroll {
        float: none;
        margin-left: 5rem;
        margin-top: -8rem;
    }

    .timeline-container {
        padding-bottom: 1rem;
        margin-top: 1rem;
    }

    .timeline {
        padding-bottom: 0rem;
        margin-bottom: 0;
    }

    /* Place timelime to left side */
    .timeline::after {
        left: 31px;
    }
    
    /* Full-width containers */
    .container-right, .container-left {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }
    
    /* all arrows pointing left */
    .container-right::before, .container-left::before {
        left: 60px;
        border: medium solid #fff;
        border-width: 10px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
    }
    
    /* place all right containers to left */
    .container-right {
        left: 0%;
    }

    /* have all circles line up the same */
    .container-left::after, .container-right::after {
        left: 15px;
    }
}
